<template>
    <div v-if="row.item.documents">
        <div v-for="invoice in row.item.documents.invoices">
            <div v-if="invoice.url">
                <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;
                <a target="_blank"
                   @click.prevent="$root.openDocument(invoice.name)"
                   :href="$root.getDocumentUrl(invoice.name)"
                >{{ invoice.name }}</a>
            </div>
            <div v-else>{{ invoice.name }}</div>
        </div>

        <div v-for="offering in row.item.documents.kva">
            <div v-if="offering.url">
                <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;
                <a target="_blank"
                   @click.prevent="$root.openDocument(offering.name)"
                   :href="$root.getDocumentUrl(offering.name)"
                >{{ offering.name }}</a>
            </div>
            <div v-else>{{ offering.name }}</div>
        </div>

        <template
            v-for="document_type in ['lieferschein','product_picker','shipping_label','return','combine']">
            <div v-if="row.item.documents[document_type] && row.item.documents[document_type].url">
                <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;
                <a @click.prevent="$root.openDocument(row.item.documents[document_type].name)"
                   :href="$root.getDocumentUrl(row.item.documents[document_type].name)"
                >
                    {{
                        row.item.documents[document_type].name
                    }}</a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>